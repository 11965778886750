import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Container from "components/Container"
import Link from "components/Link"
import Page from "components/Page"

import styles from "./blogPost.module.scss"

const FeaturedImage = ({ data }) => {
  if (!data) return null

  const {
    childImageSharp: { fluid },
  } = data

  return <Img className={styles.featuredImage} fluid={fluid} />
}

const Template = ({ data, pageContext }) => {
  const { next, prev } = pageContext

  const {
    html,
    frontmatter: { title, tags, date, featuredImage },
  } = data.markdownRemark

  return (
    <Page title={title} image={featuredImage}>
      <FeaturedImage data={featuredImage} />

      <Container containerClassName={styles.container} className={styles.wrap}>
        <ul className={styles.tags}>
          {tags.map(tag => (
            <li>{tag}</li>
          ))}
        </ul>

        <h1 className={styles.title}>{title}</h1>

        <div className={styles.author}>
          <img src="/dv-avatar.png" alt="Logo" />

          <p>
            <Link.Underline to="/authors/dimitar-valchanov">
              Dimitar Valchanov
            </Link.Underline>
            {"  "}• {date} • 6 min read
          </p>
        </div>

        <div
          className={styles.blogpost}
          dangerouslySetInnerHTML={{ __html: html }}
          style={{ fontFamily: "avenir" }}
        />

        {(prev || next) && (
          <div className={styles.articlesNav}>
            {prev && (
              <Link className={styles.prevArticle} to={prev.frontmatter.path}>
                <span>
                  <Img
                    className={styles.prevNextImg}
                    fluid={prev.frontmatter.featuredImage.childImageSharp.fluid}
                  />
                </span>

                <span>
                  <p>Previous Article</p>
                  <h4>{prev.frontmatter.title}</h4>
                </span>
              </Link>
            )}

            {next && (
              <Link className={styles.nextArticle} to={next.frontmatter.path}>
                <span>
                  <p>Next Article</p>
                  <h4>{next.frontmatter.title}</h4>
                </span>

                <span>
                  <Img
                    className={styles.prevNextImg}
                    fluid={next.frontmatter.featuredImage.childImageSharp.fluid}
                  />
                </span>
              </Link>
            )}
          </div>
        )}
      </Container>
    </Page>
  )
}

export const query = graphql`
  query($pathSlug: String!) {
    markdownRemark(frontmatter: { path: { eq: $pathSlug } }) {
      html
      frontmatter {
        title
        excerpt
        date
        tags
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default Template
